import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import Vuex from 'vuex'
import store from './store'
import { trim, cnpjcpf, cnpj, cpf, rg, phone, cep } from '../'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { VueMaskDirective } from 'v-mask';
import "./plugins/vuetify-mask.js";
import VueHtmlToPaper from 'vue-html-to-paper';
import IdleVue from "idle-vue";
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import Print from 'vue-print-nb'

Vue.use(Print);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import VDatetimePicker from 'v-datetime-field'

import VueMeta from 'vue-meta'


Vue.prototype.moment = moment
Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 60 *  1000 * 60 , /* 10 Minutos*/
  startAtIdle: false
});


const optionsVueHtmlToPaper = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, optionsVueHtmlToPaper);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.use(VueMeta)

Vue.use(VDatetimePicker)

Vue.config.productionTip = false
//directives
Vue.directive('mask', VueMaskDirective);
Vue.directive('rg', rg)
Vue.directive('cpf', cpf)
Vue.directive('cep', cep)
Vue.directive('trim', trim)
Vue.directive('cnpj', cnpj)
Vue.directive('phone', phone)
Vue.directive('cnpjcpf', cnpjcpf)
Vue.use(Vuex)

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2,options);


window._Vue = new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
