import services from '../../http'
import * as storage from './storage'
import * as types from './mutation-types'

export const ActionSendDividaLote = (context, payload) => {

    return services.auth.sendLoteNegativar( payload).then(res => {
        if(res.data.erro){
            return res
            
        }else{
           
            return res

       }
    })
   
}
export const ActionEmpresaComplete = (context, payload) => {
  
    return services.auth.registerEmpresa(payload).then(res => {
        if(res.data.erro){
            return res
            
        }else{
            return res

       }
    })
   
}

export const registerEmpresaFile = (context, payload) => {

    return services.auth.registerEmpresaFile( payload).then(res => {
        if(res.data.erro){
            return res
            
        }else{
           
            return res

       }
    })
   
}
export const enviarDividaFile = ( context, payload) => {
  
    return services.auth.enviarDividaFile(payload).then(res => {
        return res
    })
}

export const ActionRegisterComplete = ({dispatch, state}, payload) => {
  
    return services.auth.registerComplete({id:state.user.id }, payload).then(res => {
        dispatch('ActionSetUser', res.data.user)
    })
   
}

export const registerCompleteFile = ({dispatch, state}, payload) => {

    return services.auth.registerCompleteFile({id:state.user.id }, payload).then(res => {
        if(res.data.erro){
            return res
            
        }else{
            dispatch('ActionSetUser', res.data.user)
            return res

       }
    })
   
}
export const ActionDomainLogin = ( {dispatch} , payload) => {
        dispatch('ActionSetDomain', payload)
}

export const ActionDoLogin = ( {dispatch} , payload) => {

    return services.auth.login(payload).then(res => {
        
       dispatch('ActionSetUser', res.data.user)
       dispatch('ActionSetToken', res.data.access_token)
    })
   
}

export const ActionSelectDomain = ( {dispatch} , payload) => {

    return services.auth.selectDomain(payload).then(res => {

        dispatch('ActionSetUser', res.data.user)
        dispatch('ActionSetToken', res.data.access_token)
    })

}

export const ActionRegister = (context, payload) => {

    return services.auth.register(payload).then(res => {
        return res
    })
   
}


export const ActionCheckToken = ({ dispatch, state}) => {
    if(state.token){
        return Promise.resolve(state.token)
    }
    const token = storage.getLocalToken()
    const domain = storage.getLocalDomain()

    if(!token){
        return Promise.reject(new Error('Token Inválido'))
    }

    if (!domain){
        return Promise.reject(new Error('Empresa Inválida'))
    }
    
    dispatch('ActionSetToken', token)
    dispatch('ActionSetDomain', JSON.parse(domain))
    return dispatch('ActionloadSession')
}

export const ActionloadSession = (  { dispatch }) =>{
    
    // return  async  () => {
        
    //         const { data: { user } } = await services.auth.loadSession()
    //         console.log(user)
    //         dispatch('ActionSetUser', user)
            
    //     // resolve()
    //     // } catch (error) {
    //     //     console.log('ss')
    //     //     dispatch('ActionSignOut')
    //       //   reject(error)
    //     // }
    // }

    return (async () => {

        try {
            const { data: { user } } =  await services.auth.loadSession()
            dispatch('ActionSetUser', user)
         //   resolve()
            
        } catch (error) {
            dispatch('ActionSignOut')
           //  reject(error)
        }

        
      })().catch(e =>  console.log(e)); // Catches it.
}

export const ActionSignOut = ({ dispatch }) => {
    storage.setHeaderToken('')
    storage.deleteLocalToken()
    storage.deleteLocalDomain()

    dispatch('ActionSetUser', {})
    dispatch('ActionSetDomain', {})
    dispatch('ActionSetToken', '')
}

export const ActionSetUser = ({ commit }, payload) => {
    commit(types.SET_USER, payload)
}

export const ActionSetDomain = ({ commit }, payload) => {

    storage.setLocalDomain(JSON.stringify(payload))
    commit(types.SET_DOMAIN, payload)
}

export const ActionSetToken = ({ commit }, payload) => {
    storage.setLocalToken(payload)
    storage.setHeaderToken(payload)
    commit(types.SET_TOKEN, payload)
}