import { setBearerToken }  from '../../http'

export const setHeaderToken = token => setBearerToken(token)
export const getLocalToken = () => localStorage.getItem('token')
export const deleteLocalToken = () => localStorage.removeItem('token')
export const setLocalToken = token => localStorage.setItem('token', token)


export const getLocalDomain = () => localStorage.getItem('domain')
export const deleteLocalDomain = () => localStorage.removeItem('domain')
export const setLocalDomain = domain => localStorage.setItem('domain', domain)