import Vue from 'vue'
import VueResource from 'vue-resource'
import   services   from './services'
import interceptors from './interceptors'
import dotenv from 'dotenv'


dotenv.config()

Vue.use(VueResource)
    
const http = Vue.http

http.options.root = process.env.VUE_APP_API_URL

http.interceptors.push(interceptors)

Object.keys(services).map(service => {
    services[service] = Vue.resource('', {}, services[service])
})

const setBearerToken = token => {
    http.headers.common['Authorization'] = `Bearer ${token}`
}


export default  services
export { http,setBearerToken }