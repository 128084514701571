export  default {
    login: { method:'post', url:'login'},
    selectDomain: { method:'post', url:'selectDomain'},
    loadSession: { method:'get', url:'loadSession'},
    register: { method:'post', url:'register'},
    registerComplete: { method:'post', url:'registerComplete{/id}'},
    registerCompleteFile: { method:'post', url:'registerComplete{/id}', body: FormData},
    gettermos: { method:'get', url:'termo'},
    enviarDividaFile: { method:'post', url:'enviarDividaFile', body: FormData},
    getUsuario: { method:'get', url:'getUsuario'},
    registerEmpresa: { method:'post', url:'registerEmpresa'},
    registerEmpresaFile: { method:'post', url:'registerEmpresa', body: FormData},
    sendLoteNegativar: { method:'post', url:'sendLoteNegativar', body: FormData},
}