<template>
  <v-app >
  <v-main class="fill-height " fluid>
    <v-main class="back"></v-main>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
   </v-main>
    
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
   
  },

 
};
</script>
<style>
 @import url('https://fonts.googleapis.com/css?family=Montserrat'); 
.v-application {
  font-family: 'Montserrat';
  color: #000!important;
  background-color: #FFF;
}
.back {
  background-color: #f27c00;
    font-family: 'Montserrat';
  height: 100%;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  z-index: 0;
}

.v-text-field{
    font-family: 'Montserrat';
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active, .fade-leave-active{
  transition: all .3s ease;
}
.v-btn{
  border-radius: 30px;
    font-family: 'Montserrat';
}

.v-container {
    font-family: 'Montserrat';
   
    margin-left: 5px;
    margin-right: 5px;
    max-width: max-content;
    margin-bottom: 5px;
 
}
</style>
