import Vue from 'vue'
import route from './routes'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach'




Vue.use(VueRouter)


const router = new VueRouter(route);
router.mode = "html5";

router.beforeEach(beforeEach)

export default router
