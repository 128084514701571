import store from '../store'

export default async (to, from, next) => {
    document.title =  "StoneUP Negativa "
    //document.icon
    //se a rota for dirente de login e o state retornar false
    if(to.name !== 'login' && to.name !== 'cadastrar' && to.name !== 'recuperaracesso' && to.name !=='informarsenha'  &&!store.getters['auth/hasToken']){
        try {
            await store.dispatch('auth/ActionCheckToken')
            
            next({ name: to.name })
        } catch (error) {
        
            next({ name: 'login' })
       
            
        }
    }else{

        if(to.name === 'recuperaracesso' && store.getters['auth/hasToken']){
            next({ name: 'home' })
        }else{
            next()
        }
        
        if(to.name === 'login' && store.getters['auth/hasToken']){
            next({ name: 'home' })
        }else{
            next()
        }
        if(to.name === 'cadastrar' && store.getters['auth/hasToken']){
            next({ name: 'home' })
        }else{
            next()
        }
       

    }
}