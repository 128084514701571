
const router = {
  mode: "history",
  base: "negativa",
  routes: [
    {
      path: "/",
      name: "",
      redirect: "home",
      component: () =>
        import(
            /* webpackChunkName: "about" */ "@/containers/Dashboard"
        ),
      children: [

        {
          path: "/home",
          name: "Home",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/home/HomeComponent"),
            meta: {
              title: 'Home Page - Example App',
              metaTags: [
                {
                  name: 'description',
                  content: 'The home page of our example app.'
                },
                {
                  property: 'og:description',
                  content: 'The home page of our example app.'
                }
              ]
            }
        },

        
        {
          path: "/cadastroCompleto",
          name: "Completar Cadastro",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/cadastrosUsuario/FormCasdastroComponent"),
        },
        {
          path: "/empresas",
          name: "Cadastro de Credor",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/empresas/FormCasdastroComponent"),
          props: true
        },
 
        {
          path: "/empresasList",
          name: "Cadastro de Credores",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/empresas/EmpresasListComponent"),
        },
        {
          path: "/usuarios",
          name: "Usuários",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/usuarios/UsuariosComponent"),
        },
        {
          path: "/perfisacesso",
          name: "Perfil de Acesso",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/acesso/perfis/perfisComponente"),
        },
        {
          path: "/permissoesperfil",
          name: "Permissões para o perfil",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/acesso/perfis/perfisPermissoesComponente"),
        },
        {
          path: "/permissoes",
          name: "Permissoes de acesso",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/acesso/permissoes/permissoesComponente"),
        },
        {
          path: "/perfil",
          name: "Perfil de usuário",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/login/PerfilComponent"),
        },
        {
          path: "/predividas",
          name: "Solicitação do Consumidor",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/predividas/predividasComponent"),
        },
        {
          path: "/servicosutilizados",
          name: "Serviços Utilizados",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/relatorios/ServicosUtilizadosComponent"),
        },
        {
          path: "/negativar",
          name: "negativar",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/negativar/NegativarComponent"),
        },
        {
          path: "/consultas",
          name: "Consulta",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/consultas/ConsultaComponent"),
        },
        {
          path: "/consulta/:id/detalhes",
          name: "Consulta ao Consumidor",
          props: true,
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/consultas/ConsultaDetailsComponent"),
        },


        {
          path: "/inclusaoSimples",
          name: "Inclusão Simples",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/inclusao/SimplesComponent"),
        },
        {
          path: "/inclusaoLote",
          name: "Inclusão em Lote",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/inclusao/LoteComponent"),
        },
        {
          path: "/All",
          name: "All",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/alldividas/AllDividasComponent"),
        },
        {
          path: "/semPermissao",
          name: "semPermissao",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/NoPermissionComponent"),
        },
        {
          path: "/protocols",
          name: "Consulta de Títulos",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/protocolos/ProtocolosComponent"),
        },
        {
          path: "/simple_low",
          name: "Solicitação de baixa",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/baixa/simples/SimplesComponent"),
        },
        {
          path: "/agreement_config",
          name: "Configurações de Acordo",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/configuracoes/acordos/AcordosComponent"),
        },
        {
          path: "/revenues",
          name: "Faturas",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/faturamento/faturamentoComponent"),
        },
        {
          path: "/revenuesDetails",
          name: "Detalhes da Fatura",
          component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/faturamento/faturaDetalhesComponent"),
        }

      ],
    },
    {
      path: "/bemvindo",
      name: "Bem Vindo",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/pages/saudacoes/SaudacoesComponent"),
      props: true
    },
    {
      path: "/primeiraempresa",
      name: "Cadastro de Primeiro Credor",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/pages/empresas/FormCasdastroComponent"),
      props: true
    },

    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/pages/login/LoginComponent"),
      meta: {
        title: 'Home Page - Example App',
      }
    },
    {
      path: "/recuperaracesso",
      name: "recuperaracesso",
      component: () =>
        import(/* webpackChunkName: "recuperaracesso" */ "@/pages/login/EsqueciASenha"),
    },
    {
      path: "/informarsenha",
      name: "informarsenha",
      component: () =>
        import(/* webpackChunkName: "recuperaracesso" */ "@/pages/login/RecuperarSenha"),
    },
    {
      path: "/cadastrar",
      name: "cadastrar",
      component: () =>
        import(/* webpackChunkName: "cadastrar" */ "@/pages/login/CadastroComponent"),
    },
    {
      path: "/selecionarEmpresa",
      name: "selecionarEmpresa",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/pages/SelecionarEmpresa/EmpresasComponent"),
    },


  ],
};


export default router
